import { environment } from '@env/environment';
import { reflectComponentType, Type } from "@angular/core";

export class MiscExtension {

    static deepClone<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    static getUrl(compName: string, args: any[]) {
        let match = this.getMatch(compName, args?.length);
        if (!match)
            return null;

        let path = match.path;
        let params = MiscExtension.extractRouteParameters(path);
        params.forEach((param, index) => {
            path = path.replace(':' + param, args[index]);
        });

        return path;
    }

    static getMatch(compName: string, argsLength?: number) {
        let match = environment.routeDictionary[compName];
        if (!match)
            return null;

        if (!match.length)
            return null;

        if (match.length == 1 || !argsLength)
            return match[0];

        let perfectMatch =
            match.find(x => this
                .extractRouteParameters(x.path).length == argsLength);
        if (perfectMatch)
            return perfectMatch;

        return match[0];
    }

    static extractRouteParameters(url: string): string[] {
        const paramPattern = /:([a-zA-Z0-9]+)/g;
        const queryPattern = /[?&]([^=]+)=:([^&]+)/g;

        const routeParams = [];
        let match;

        // Extract route parameters (e.g., :varA and :varB)
        while ((match = paramPattern.exec(url)) !== null) {
            routeParams.push(match[1]);
        }

        // Extract query parameters (e.g., valC=:varD)
        while ((match = queryPattern.exec(url)) !== null) {
            routeParams.push(match[2]);
        }

        return routeParams;
    }

    static getSelector(compRef: Type<any>) {
        let compMetaData = reflectComponentType(compRef);
        return compMetaData?.selector;
    }
}