import { KeyPairType } from './key-pair-type';
import { HttpStatusCode } from '@angular/common/http';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { ButtonFillMode, ButtonThemeColor } from '@progress/kendo-angular-buttons';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { NavigationExtras } from '@angular/router';
import { Type } from '@angular/core';

export class ProblemDetails {
    appName!: string;
    detail!: string;
    exception!: string;
    instance!: string;
    type!: string;
    title!: string;
    status!: HttpStatusCode;
    traceId!: string;
    errors?: { [myKey: string]: string[] };
}

export class KeyPairRequestModel {
    pageSize? = 50;
    pairType: KeyPairType | string = KeyPairType.None;
    searchText?: string;
    showSystemStatuses? = false;
    ignoreStatusFilter? = false;
    additionalMetaData?: any;
    parentModel?: any;
    exactMatch? = false;
    originalValue?: any;
}

export class WebApiDataSourceResult<T> {
    data!: T[];
    total!: number;
}

export class IntKeyPair {
    displayText?: string;
    dataField?: number;
    templateData?: string;
    templateDataParsed?: any;
}

export class StringKeyPair {
    displayText?: string;
    dataField?: string;
    templateData?: string;
    templateDataParsed?: any;
}

export abstract class BaseModel {
    id = 0;
    lastChangedOn?: Date | null = null;
    lastChangedBy?: string | null = null;
    createdOn?: Date | null = null;
    createdBy?: string | null = null;
}

export class UserModel {
    displayName? = '';
    givenName? = '';
    id? = '';
    mail? = '';
    mobilePhone? = '';
    preferredLanguage? = '';
    surname? = '';
    userPrincipalName? = '';
    jobTitle? = '';
    img?: any = '';
    permissions?: string[] = [];
}

export class UrlConfigModel {
    alias!: string;
    artifactName!: string;
    overrideUrl?: string;
    relativeUrl?: string;
    useLocal?: boolean;
    scopes?: string[];
}

export interface GetServiceResponsesModel {
    responses: GetServiceResponseModel[];
}

export interface GetServiceResponseModel {
    metaDataJSON: string;
    serviceEndpoint: string;
    featureEndpoint: string;
    localEndpoint: string;
    namespace: string;
}

export enum ArtifactType {
    WebApi = 1,
    Website = 2,
}

export class FormEventModel {
    formEventType!: string | FormEventType;
    data?: any;
}

export enum FormEventType {
    Save = 'save',
    SaveAndReturn = 'save_return',
    SaveAndNew = 'save_new',
    Reset = 'reset',
    Other = 'other',
    Close = 'close',
    ParamsChanged = 'params_changed',
    Delete = 'delete',
    ReturnToParent = 'return_to_parent',
    Submit = 'submit',
}

export class ButtonDropModel {
    text?: string;
    svgIcon?: SVGIcon;
    action?: string;
    disabled?: boolean;
    click?: (dataItem?: any) => void;
    dataItem?: any;
    theme?: ButtonThemeColor;
    fillMode?: ButtonFillMode;
}

export enum NavType {
    Parent = 'Parent',
    Current = 'Current',
    New = 'New'
}

export enum KendoGridColumnDataType {
    None = 'none',
    KeyPair = 'keyPair',
    Boolean = 'bool',
    Date = 'date',
    DateTime = 'datetime'
}

export interface AppInfoModel {
    buildDefinitionName: string;
    buildSourceBranch: string;
    buildNumber: string;
    buildDeploymentTime: string;
    buildReleaseId: string;
}

export interface Shortcut {
    id: string;
    label: string;
    description?: string;
    icon: string;
    link: string;
    useRouter: boolean;
}

export interface SmartBreadCrumbItem extends BreadCrumbItem {
    navExtra?: NavigationExtras;
    commands?: any[];
    eventName?: string;
}

export interface RouteDictionary {
    [componentName: string]: RouteInfo[];
}

export interface RouteInfo {
    title?: string;
    path: string;
    componentRef?: Type<any>;
}