import { AppInfoModel, RouteDictionary, UserModel } from '@models/shared/shared-models';
import { apiUrlStore } from './api-url-store';

export const environment = {
    hmr: true,
    name: 'prd',
    auth0: {
        domain: 'auth.myapprig.com',
        clientId: 'Wp4vmQjhCZ2WyJDXQYatzonivZSb2fcy',
        audience: "https://myapprig.com/prd-svc-item-patrol",
        allowedList: [
            "https://www.itempatrol.com/api/*"
        ]
    },
    urlStore: apiUrlStore,
    appInfo: {} as AppInfoModel,
    userInfo: {} as UserModel,
    routeDictionary: {} as RouteDictionary,
    activeTenants: [],
    isProd() {
        return this.name === 'prd';
    }
};