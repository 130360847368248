import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { BasePopupComponent } from '@shared/components/base-popup.component';
import { MiscExtension } from '@helpers/extensions/misc-extension';

@Injectable({
    providedIn: 'root'
})

export class CustomDialogService {
    private inPlayDialogs = [] as DialogRef[];
    private globalClickListener: () => void;
    private renderer: Renderer2;

    constructor(private dialogService: DialogService,
                private rendererFactory: RendererFactory2) {

        this.renderer = this.rendererFactory.createRenderer(null, null);
        document.onkeydown = ev => {
            if (ev.key === 'Escape') {
                this.closeLastPopup();
            }
        };
    }

    open(options: DialogSettings) {
        return this.openDialog(options);
    }

    //Use this to open any component name and param object
    openBaseComponentByName(componentName: string, paramObject?: any) {
        let argsLength = 0;
        if (paramObject)
            argsLength = Object.keys(paramObject).length;

        let routeMatch =
            MiscExtension.getMatch(componentName, argsLength);
        if (!routeMatch) {
            console.error(`No matching component found with name ${componentName}`);
        }

        this.openBaseComponent(routeMatch.componentRef as Function, paramObject);
    }

    //Use this to open any component name and numbered args
    openBaseComponentByNameAndArgs(componentName: string, args: any[]) {
        let routeMatch =
            MiscExtension.getMatch(componentName, args?.length);
        if (!routeMatch) {
            console.error(`No matching component found with name ${componentName}`);
        }

        let params = MiscExtension.extractRouteParameters(routeMatch.path);
        let paramObj = {};
        params.forEach((value, index) => {
            paramObj[value] = args[index];
        });

        this.openBaseComponent(routeMatch.componentRef as Function, paramObj);
    }

    //Use this to open any component ref and numbered args
    openBaseComponent(templateRef: Function, metaData?: any) {
        this.openComponent(BasePopupComponent, {
            dialogTitle: 'ignore',
            data: {
                compToActivate: templateRef,
                inputData: metaData
            }
        });
    }

    openComponent(templateRef: Function, metaData?: any) {
        let options = new DialogSettings();
        options.content = templateRef;

        if (metaData?.dialogTitle) {
            options.title = metaData.dialogTitle;
            delete metaData.dialogTitle;
        }

        if (metaData?.minHeight) {
            options.minHeight = metaData?.minHeight;
        }

        let dialogRef = this.openDialog(options);
        if (metaData && metaData.data) {
            let keys = Object.keys(metaData.data);
            keys.forEach(key => {
                    try {
                        dialogRef.content.setInput(key, metaData.data[key]);
                    } catch {
                    }
                }
            );
        }

        return dialogRef;
    }

    closeAll() {
        this.closeInPlayPopups();
    }

    private openDialog(options: DialogSettings) {
        if (!options.autoFocusedElement)
            options.autoFocusedElement = '#autoFocusElement';

        options.animation = {
            direction: 'down',
            duration: 300,
            type: 'fade'
        };

        if (!options.minWidth)
            options.minWidth = '80%';

        if (!options.maxWidth)
            options.maxWidth = '80%';

        if (!options.maxHeight)
            options.maxHeight = '80%';

        if (!options.autoFocusedElement)
            options.autoFocusedElement = 'div';

        if (!options.title)
            options.title = '';

        if (options.title === 'ignore')
            options.title = undefined;

        if (options.title === '')
            options.title = undefined;

        options.preventAction = value => {
            if (value == typeof DialogCloseResult)
                return false;

            return !(value instanceof DialogCloseResult);
        };

        let dialogRef = this.dialogService.open(options);
        this.inPlayDialogs.push(dialogRef);
        this.handleDialogClose(dialogRef);

        if (this.inPlayDialogs.length === 1) {
            this.addGlobalClickListener();
        }

        return dialogRef;
    }

    private addGlobalClickListener() {
        document.body.classList.add('overflow-hidden');
        this.globalClickListener =
            this.renderer.listen('document', 'click', (event: MouseEvent) => {
                this.onDocumentClick(event);
            });
    }

    private onDocumentClick(event: MouseEvent) {
        const overlayElements = document.querySelectorAll('.k-overlay');
        const clickTarget = event.target as HTMLElement;
        const isClickOutsidePopup = overlayElements.length &&
            Array.from(overlayElements).some(overlay =>
                overlay.contains(clickTarget));

        if (isClickOutsidePopup && this.inPlayDialogs.length > 0) {
            this.closeLastPopup();
        }
    }

    private removeGlobalClickListener() {
        document.body.classList.remove('overflow-hidden');
        if (this.globalClickListener) {
            this.globalClickListener();
            this.globalClickListener = null;
        }
    }

    private handleDialogClose(dialogRef: DialogRef) {
        let that = this;
        dialogRef.result.subscribe(function (a) {
            if (a instanceof DialogCloseResult) {
                that.inPlayDialogs =
                    that.inPlayDialogs
                        .filter(ref => ref !== dialogRef);

                if (that.inPlayDialogs.length === 0) {
                    that.removeGlobalClickListener();
                }
            }
        });
    }

    private closeLastPopup() {
        let lastDialog = this.inPlayDialogs.pop();
        lastDialog?.close();
    }

    private closeInPlayPopups() {
        let diagList = this.inPlayDialogs.reverse();
        while (diagList.length) {
            let diagToClose = diagList.pop();
            setTimeout(function () {
                diagToClose?.close();
            }, 100);
        }
    }
}