import { UrlConfigModel } from '@models/shared/shared-models';

export const apiUrlStore: UrlConfigModel[] = [
    {
        alias: '@selfApi',
        artifactName: 'ItemPatrol.WebApi',
        overrideUrl: "https://www.itempatrol.com",
        relativeUrl: "/api",
        scopes: [ "https://myapprig.com/prd-svc-item-patrol" ]
    }
];