import { Component, ComponentRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { Params, RouterLink } from '@angular/router';
import { DynamicComponentDirective } from '@shared/directives/dynamic-component.directive';
import { FormEventModel, FormEventType, RouteDictionary } from '@models/shared/shared-models';
import { DialogContentBase, DialogRef, DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { hyperlinkOpenIcon } from '@progress/kendo-svg-icons';
import { DatePipe, NgIf } from '@angular/common';
import { IconsModule } from '@progress/kendo-angular-icons';
import { MatAnchor, MatIconAnchor } from '@angular/material/button';
import { EventService } from '@services/infrastructure/event.service';
import { MiscExtension } from '@helpers/extensions/misc-extension';

@Component({
    selector: 'base-popup',
    standalone: true,
    imports: [
        DynamicComponentDirective,
        DialogsModule,
        ButtonsModule,
        DatePipe,
        NgIf,
        IconsModule,
        MatIconAnchor,
        MatAnchor,
        RouterLink
    ],
    template:
        `
            <kendo-dialog-titlebar>
                <a class="font-normal italic text-xl hover:underline hover:text-blue-400"
                   [routerLink]="formData.route"
                   *ngIf="formData?.title?.length > 0">
                    <kendo-svg-icon [icon]="formData.iconSet.navIcon"
                                    size="large">
                    </kendo-svg-icon>
                    {{ formData.title }}
                </a>
            </kendo-dialog-titlebar>
            <div class="w-full">
                <input class="w-0" id="autoFocusElement"/>
                <ng-template dynamicComponent></ng-template>
            </div>
        `
})
export class BasePopupComponent extends DialogContentBase
    implements OnInit, OnDestroy {

    static routes: RouteDictionary;

    formData = {
        compSelector: '',
        destroy$: new Subject<any>(),
        routeSub: new ReplaySubject<Params>(Number.MAX_VALUE),
        title: '',
        route: '',
        currentOutlet: 'primary',
        dialogPayLoad: {},
        iconSet: {
            navIcon: hyperlinkOpenIcon
        },
        componentInstance: {}
    };

    @Input() dialogTitle?: string = '';
    @Input() compToActivate?: any = null;
    @Input() inputData?: any = null;
    protected destroy$ = new Subject();
    @ViewChild(DynamicComponentDirective)
    private dynamicHost!: DynamicComponentDirective;

    constructor(private eventService: EventService,
                private dialogRef: DialogRef) {
        super(dialogRef);
    }

    ngOnInit(): void {
        this.formData.title = this.dialogTitle ?? '';

        if (this.inputData) {
            let keys = Object.keys(this.inputData);
            keys.forEach(key => {
                let param = {} as Params;
                param[key] = this.inputData[key];
                this.formData.routeSub.next(param);
            });
        }

        let that = this;
        setTimeout(function () {
            that.initializePopup();
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    initializePopup() {
        const viewContainerRef = this.dynamicHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef =
            viewContainerRef.createComponent(this.compToActivate);
        this.wireInstance(componentRef);
    }

    private wireInstance(componentRef: ComponentRef<unknown>) {
        let compInstance = componentRef.instance as any;
        let eventName = compInstance?.formData?.eventName ?? '';

        if (eventName) {
            this.eventService
                .listen(eventName)
                .pipe(takeUntil(this.formData.destroy$))
                .subscribe(value => {
                    this.onFormEvents(value);
                });
        }

        this.formData.routeSub
            .pipe(takeUntil(this.formData.destroy$))
            .subscribe(x => {
                let keys = Object.keys(x);
                keys.forEach(key => componentRef.setInput(key, x[key]));
            });

        this.formData.compSelector = MiscExtension.getSelector(componentRef.componentType);
        this.formData.componentInstance = compInstance;
        this.setupPageTitle();
    }

    private setupPageTitle() {
        let possibleRoute = MiscExtension.getMatch(this.formData.compSelector);
        if (possibleRoute) {
            this.formData.title = possibleRoute.title;
            this.formData.route =
                this.getLinks(possibleRoute.path, this.formData.componentInstance);
        }
    }

    private onFormEvents(formEvent: FormEventModel) {
        if (formEvent.formEventType == FormEventType.ReturnToParent ||
            formEvent.formEventType == FormEventType.SaveAndReturn ||
            formEvent.formEventType == FormEventType.Delete) {
            this.dialogRef.close();
        } else {
            this.setupPageTitle();
        }
    }

    private getLinks(path: string, instance: any) {
        let routeParams = MiscExtension.extractRouteParameters(path);
        routeParams.forEach((value, index) => {
            let replacementValue = instance[value] as string;
            if (replacementValue) {
                path = path.replace(':' + value, replacementValue);
                if (index == routeParams.length - 1)
                    this.formData.title += ` (${replacementValue})`;
            } else {
                path = path.replace(':' + value, '0');
            }
        });

        return path;
    }
}